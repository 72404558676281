import styled from "styled-components"

const H1 = styled.h1`
  color: ${({ white, theme }) =>
    white ? theme.colors.white : theme.colors.secondary};
  margin: 0 0 25px 0;
  font-weight: 900;
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;

  ${({ theme }) => theme.media.tablet} {
    font-size: 40px;
    line-height: 50px;
    margin: 0 0 25px 0;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: ${({ big }) => (big ? "70px" : "50px")};
    line-height: 60px;
    margin: 0 0 30px 0;
  }
`

export default H1
