import styled from "styled-components"

const H2 = styled.h2`
  color: ${({ gold, theme }) =>
    gold ? theme.colors.primary : theme.colors.black};
  margin: 0 0 20px 0;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: 3px;
  position: relative;

  ${({ theme }) => theme.media.tablet} {
    font-size: 30px;
    line-height: 35px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 40px;
    line-height: 40px;
  }
`

export default H2
